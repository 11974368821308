import { Card, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { MenuNavbar } from "../components/index";

const SubmittedSupply = () => {
  const { screenSize } = useStateContext();
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#eae41f" }}>
      <MenuNavbar />
      <div
        style={{
          backgroundImage: `url("/SpiderWebTransparent.png")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          style={{
            paddingTop: "40px",
            paddingBottom: "40px",
            paddingLeft: screenSize >= 930 ? "80px" : "10px",
            paddingRight: screenSize >= 930 ? "80px" : "10px",
          }}
        >
          <div style={{ textAlign: "center", marginTop: "40px" }}>
            <h1
              style={{
                color: "green",
                fontWeight: "700",
                textShadow: "2px 2px 2px white",
              }}
            >
              Produk / Jasa Anda Telah Terkirim!
            </h1>
            <h1
              style={{
                color: "green",
                fontWeight: "700",
                textShadow: "2px 2px 2px white",
              }}
            >
              Kami Bantu Carikan Pelanggan!
            </h1>
          </div>
          <div className="text-center" style={{ marginTop: "80px" }}>
            <div style={{ marginTop: "40px" }}>
              <Button
                onClick={() => {
                  navigate("/submitSupply");
                }}
                style={{ backgroundImage: "linear-gradient(red, orange)" }}
              >
                <h4>Anda Menawarkan Produk / Jasa Lain?</h4>
                <p>KLIK DISINI!</p>
              </Button>
            </div>
            <div style={{ marginTop: "40px" }}>
              <Button
                onClick={() => {
                  navigate("/submitDemand");
                }}
                style={{ backgroundImage: "linear-gradient(red, orange)" }}
              >
                <h4>Anda Punya Kebutuhan Produk / Jasa?</h4>
                <p>KLIK DISINI!</p>
              </Button>
            </div>
            <div style={{ marginTop: "40px" }}>
              <Button
                onClick={() => {
                  navigate("/tentangKami");
                }}
                style={{ backgroundImage: "linear-gradient(red, orange)" }}
              >
                <h4>Ingin Tau Siapa Kami?</h4>
                <p>KLIK DISINI!</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmittedSupply;
