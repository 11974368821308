import { Container, Nav, Navbar } from "react-bootstrap";

const MenuNavbar = () => {
  return (
    <div style={{ backgroundColor: "#eae41f" }}>
      <Navbar
        expand="lg"
        data-bs-theme="dark"
        style={{ backgroundColor: "red" }}
      >
        <Container>
          <Navbar.Brand href="/" style={{ fontFamily: "cursive" }}>
            JARING UMKM!
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/tentangKami">Tentang Kami</Nav.Link>
              <Nav.Link href="/kontak">Kontak</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default MenuNavbar;
