import React, { useContext, useState, useEffect } from "react";
import { useStateContext } from "./contexts/ContextProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  MainPage,
  SubmitDemand,
  SubmittedDemand,
  SubmitSupply,
  SubmittedSupply,
  TentangKami,
  Kontak,
} from "./pages/index";
import { Footer, ScrollToTop } from "./components/index";

const App = () => {
  const { screenSize, setScreenSize } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "#eae41f",
      }}
    >
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/submitDemand" element={<SubmitDemand />} />
        <Route path="/submittedDemand" element={<SubmittedDemand />} />
        <Route path="/submitSupply" element={<SubmitSupply />} />
        <Route path="/submittedSupply" element={<SubmittedSupply />} />
        <Route path="/tentangKami" element={<TentangKami />} />
        <Route path="/kontak" element={<Kontak />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
