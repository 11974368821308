import React, { useState } from "react";
import axios from "axios";
import { Card, Form, Button } from "react-bootstrap";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tempUrl, useStateContext } from "../contexts/ContextProvider";
import { MenuNavbar } from "../components/index";

const SubmitSupply = () => {
  const { screenSize } = useStateContext();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [open, setOpen] = useState(false);
  const [namaLengkapSupply, setNamaLengkapSupply] = useState("");
  const [namaBisnisSupply, setNamaBisnisSupply] = useState("");
  const [lokasiSupply, setLokasiSupply] = useState("");
  const [kontakSupply, setKontakSupply] = useState("");
  const [produkJasaSupply, setProdukJasaSupply] = useState("");
  const [keunikanprodukJasaSupply, setKeunikanProdukJasaSupply] = useState("");
  const [
    tantanganTerbesarBisnisSaatIniSupply,
    setTantanganTerbesarBisnisSaatIniSupply,
  ] = useState("");
  const [
    masalahPeningkatanPenjualanSupply,
    setMasalahPeningkatanPenjualanSupply,
  ] = useState("");
  const [apaYangTelahAndaLakukanSupply, setApaYangTelahAndaLakukanSupply] =
    useState("");
  const [solusiIdealSupply, setSolusiIdealSupply] = useState("");
  const [
    kesulitanMendapatkanProdukJasaSupply,
    setKesulitanMendapatkanProdukJasaSupply,
  ] = useState("");
  const [
    apakahBersediaMasukKoneksiSupply,
    setApakahBersediaMasukKoneksiSupply,
  ] = useState("YA");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const saveSubmitSupply = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      try {
        await axios.post(`${tempUrl}/saveSubmitSupply`, {
          namaLengkapSupply,
          namaBisnisSupply,
          lokasiSupply,
          kontakSupply,
          produkJasaSupply,
          keunikanprodukJasaSupply,
          tantanganTerbesarBisnisSaatIniSupply,
          masalahPeningkatanPenjualanSupply,
          apaYangTelahAndaLakukanSupply,
          solusiIdealSupply,
          kesulitanMendapatkanProdukJasaSupply,
          apakahBersediaMasukKoneksiSupply,
        });
        navigate("/submittedSupply");
      } catch (error) {
        alert(error.response.data.message);
      }
    } else {
      setOpen(!open);
    }
    setValidated(true);
  };

  return (
    <div style={{ backgroundColor: "#eae41f" }}>
      <MenuNavbar />
      <div
        style={{
          backgroundImage: `url("/SpiderWebTransparent.png")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            paddingTop: "40px",
            paddingBottom: "40px",
            paddingLeft: screenSize >= 930 ? "80px" : "10px",
            paddingRight: screenSize >= 930 ? "80px" : "10px",
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="outline-primary"
              onClick={() => {
                navigate("/");
              }}
            >
              {"< Kembali"}
            </Button>
          </div>
          <p
            style={{
              color: "red",
              fontWeight: "700",
              textShadow: "2px 2px 2px white",
            }}
          >
            *Data Anda akan dijaga, tidak disebar, dan hanya akan kami gunakan
            untuk membantu menawarkan Prduk / Jasa Anda.*
          </p>
          <div style={{ textAlign: "center", marginTop: "40px" }}>
            {screenSize >= 930 ? (
              <h1
                style={{
                  color: "red",
                  fontWeight: "700",
                  textShadow: "2px 2px 2px white",
                }}
              >
                Beri Tau Produk / Jasa Anda!
              </h1>
            ) : (
              <h4
                style={{
                  color: "red",
                  fontWeight: "700",
                  textShadow: "2px 2px 2px white",
                }}
              >
                Beri Tau Produk / Jasa Anda!
              </h4>
            )}
            <h1
              style={{
                color: "red",
                fontWeight: "700",
                textShadow: "2px 2px 2px white",
              }}
            >
              Kami Carikan Pelanggan!
            </h1>
          </div>
          <div style={{ marginTop: "40px" }}>
            <Card
              border="primary"
              style={{
                backgroundImage: "linear-gradient(orange, yellow)",
              }}
            >
              <Card.Body>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={saveSubmitSupply}
                >
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      1. Siapa nama lengkap Anda?
                    </Form.Label>
                    <Form.Control
                      required
                      value={namaLengkapSupply}
                      onChange={(e) =>
                        setNamaLengkapSupply(e.target.value.toUpperCase())
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Nama Lengkap harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      2. Apa nama bisnis Anda?
                    </Form.Label>
                    <Form.Control
                      required
                      value={namaBisnisSupply}
                      onChange={(e) =>
                        setNamaBisnisSupply(e.target.value.toUpperCase())
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Nama Bisnis harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      3. Dimana lokasi bisnis Anda?
                    </Form.Label>
                    <Form.Control
                      required
                      value={lokasiSupply}
                      onChange={(e) =>
                        setLokasiSupply(e.target.value.toUpperCase())
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Lokasi Bisnis harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      4. Kontak Anda yang dapat dihubungi (Nomor Whatsapp untuk
                      insentif, boleh IG, Facebook, dll)
                    </Form.Label>
                    <Form.Control
                      required
                      value={kontakSupply}
                      onChange={(e) =>
                        setKontakSupply(e.target.value.toUpperCase())
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Kontak Anda harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      5. Apa Produk / Jasa yang Anda jual?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      required
                      value={produkJasaSupply}
                      onChange={(e) =>
                        setProdukJasaSupply(e.target.value.toUpperCase())
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Produk / Jasa Anda harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      6. Apa keunikan dari Produk / Jasa Anda yang tidak
                      dimiliki pesaing Anda?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      required
                      value={keunikanprodukJasaSupply}
                      onChange={(e) =>
                        setKeunikanProdukJasaSupply(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Keunikan Produk / Jasa Anda harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      7. Apa tantangan terbesar yang dihadapi bisnis Anda saat
                      ini?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={tantanganTerbesarBisnisSaatIniSupply}
                      onChange={(e) =>
                        setTantanganTerbesarBisnisSaatIniSupply(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      8. Masalah apa yang bisnis Anda miliki dalam meningkatkan
                      penjualan bisnis Anda?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={masalahPeningkatanPenjualanSupply}
                      onChange={(e) =>
                        setMasalahPeningkatanPenjualanSupply(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      9. Apa yang telah Anda lakukan untuk mengatasi
                      masalah-masalah tersebut?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={apaYangTelahAndaLakukanSupply}
                      onChange={(e) =>
                        setApaYangTelahAndaLakukanSupply(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      10. Solusi ideal apa yang Anda inginkan?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={solusiIdealSupply}
                      onChange={(e) =>
                        setSolusiIdealSupply(e.target.value.toUpperCase())
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      11. Apakah bisnis Anda memiliki kesulitan dalam
                      mendapatkan stok produk atau jasa tertentu yang butuh
                      digunakan untuk menjalankan bisnis Anda?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={kesulitanMendapatkanProdukJasaSupply}
                      onChange={(e) =>
                        setKesulitanMendapatkanProdukJasaSupply(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      12. Kami akan merujuk bisnis Anda ke calon konsumen yang
                      sedang membutuhkan produk / jasa yang bisnis Anda
                      tawarkan. Apakah Anda berkenan?
                    </Form.Label>
                    <Form.Select
                      onChange={(e) =>
                        setApakahBersediaMasukKoneksiSupply(
                          e.target.value.toUpperCase()
                        )
                      }
                    >
                      <option value="YA">YA</option>
                      <option value="TIDAK">TIDAK</option>
                    </Form.Select>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Button variant="primary" type="submit">
                      Kirim
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={alertBox}>
          Data belum terisi semua!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SubmitSupply;

const alertBox = {
  width: "100%",
};
