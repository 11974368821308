import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Footer = () => (
  <footer
    className="text-center p-3 pt-5"
    style={{ backgroundColor: "#eae41f", color: "black" }}
  >
    <a href="https://www.ivankristiawan.com" style={{ color: "black" }}>
      <p>&copy; 2024 Ivan Kristiawan. All rights reserved.</p>
    </a>
  </footer>
);

export default Footer;
