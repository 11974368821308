import React from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useStateContext } from "../contexts/ContextProvider";
import { Button } from "react-bootstrap";
import JaringUmkmLogo from "../image/Logo/JaringUmkmLogo.png";

const MainPage = () => {
  const { screenSize } = useStateContext();
  const navigate = useNavigate();

  return (
    <div
      className="text-center text-white"
      style={{ backgroundColor: "#eae41f" }}
    >
      <img
        src={JaringUmkmLogo}
        alt="banner3"
        width={250}
        style={{
          marginTop: "40px",
        }}
      />
      <div>
        <h3 style={{ fontFamily: "cursive", color: "black" }}>YOGYAKARTA</h3>
      </div>
      <div style={{ marginTop: "30px" }}>
        <Button
          onClick={() => {
            navigate("/submitDemand");
          }}
          style={{ backgroundImage: "linear-gradient(red, orange)" }}
        >
          {screenSize >= 930 ? (
            <h4>Apakah Anda butuh Produk / Jasa yang Sulit Anda Temukan?</h4>
          ) : (
            <h5>Apakah Anda butuh Produk / Jasa yang Sulit Anda Temukan?</h5>
          )}
          <p>KLIK DISINI!</p>
        </Button>
      </div>
      <div style={{ marginTop: "60px" }}>
        <Button
          onClick={() => {
            navigate("/submitSupply");
          }}
          style={{ backgroundImage: "linear-gradient(red, orange)" }}
        >
          {screenSize >= 930 ? (
            <h4>
              Apakah Produk / Jasa milik Anda ingin Kami Promosikan secara
              Gratis?
            </h4>
          ) : (
            <h5>
              Apakah Produk / Jasa milik Anda ingin Kami Promosikan secara
              Gratis?
            </h5>
          )}
          <p>KLIK DISINI!</p>
        </Button>
      </div>
      <div style={{ marginTop: "60px" }}>
        <Button
          onClick={() => {
            navigate("/tentangKami");
          }}
          style={{ backgroundImage: "linear-gradient(red, orange)" }}
        >
          {screenSize >= 930 ? (
            <h4>Ingin Tau Siapa Kami?</h4>
          ) : (
            <h5>Ingin Tau Siapa Kami?</h5>
          )}
          <p>KLIK DISINI!</p>
        </Button>
      </div>
    </div>
  );
};

export default MainPage;
