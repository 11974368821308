import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import { MenuNavbar } from "../components/index";
import PanggilProgrammerIvanKontak from "../image/Kontak/PanggilProgrammerIvanKontak.png";
import PanggilProgrammerIvanKontakSmallScreen from "../image/Kontak/PanggilProgrammerIvanKontakSmallScreen.png";

const Kontak = () => {
  const { screenSize } = useStateContext();
  const navigate = useNavigate();
  return (
    <div style={{ backgroundColor: "#eae41f" }}>
      <MenuNavbar />
      <div
        style={{
          paddingTop: "20px",
          paddingBottom: "40px",
          paddingLeft: screenSize >= 930 ? "80px" : "10px",
          paddingRight: screenSize >= 930 ? "80px" : "10px",
        }}
      >
        <div style={{ paddingTop: "20px", textAlign: "center" }}>
          {screenSize >= 930 ? (
            <img src={PanggilProgrammerIvanKontak} alt="banner3" width={800} />
          ) : (
            <img
              src={PanggilProgrammerIvanKontakSmallScreen}
              alt="banner3"
              width={300}
            />
          )}
        </div>
        <div
          className="text-center text-white"
          style={{ backgroundColor: "#eae41f" }}
        >
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={() => {
                navigate("/submitDemand");
              }}
              style={{ backgroundImage: "linear-gradient(red, orange)" }}
            >
              {screenSize >= 930 ? (
                <h4>
                  Apakah Anda butuh Produk / Jasa yang Sulit Anda Temukan?
                </h4>
              ) : (
                <h5>
                  Apakah Anda butuh Produk / Jasa yang Sulit Anda Temukan?
                </h5>
              )}
              <p>KLIK DISINI!</p>
            </Button>
          </div>
          <div style={{ marginTop: "60px" }}>
            <Button
              onClick={() => {
                navigate("/submitSupply");
              }}
              style={{ backgroundImage: "linear-gradient(red, orange)" }}
            >
              {screenSize >= 930 ? (
                <h4>
                  Apakah Produk / Jasa milik Anda ingin Kami Promosikan secara
                  Gratis?
                </h4>
              ) : (
                <h5>
                  Apakah Produk / Jasa milik Anda ingin Kami Promosikan secara
                  Gratis?
                </h5>
              )}
              <p>KLIK DISINI!</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kontak;
