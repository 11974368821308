import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
// import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { MenuNavbar } from "../components/index";
import JaringUmkmLogo from "../image/Logo/JaringUmkmLogo.png";

const TentangKami = () => {
  const { screenSize } = useStateContext();
  const navigate = useNavigate();
  // const [videoId, setVideoId] = useState("hlSvfvZ3n64");
  // const opts = {
  //   height: screenSize >= 930 ? "390" : "200",
  //   width: screenSize >= 930 ? "740" : "300",
  //   playerVars: {
  //     autoplay: 1,
  //   },
  // };

  return (
    <div style={{ backgroundColor: "#eae41f" }}>
      <MenuNavbar />
      <div
        style={{
          paddingTop: "20px",
          paddingBottom: "40px",
          paddingLeft: screenSize >= 930 ? "80px" : "10px",
          paddingRight: screenSize >= 930 ? "80px" : "10px",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h2 style={{ fontWeight: "700" }}>TENTANG KAMI</h2>
        </div>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <YouTube videoId={videoId} opts={opts} />
        </div> */}
        <div style={{ textAlign: "center" }}>
          <img src={JaringUmkmLogo} alt="banner3" width={250} />
        </div>
        <div style={{ textAlign: "center" }}>
          <h3 style={{ fontFamily: "cursive", color: "black" }}>YOGYAKARTA</h3>
        </div>
        <Card
          border="primary"
          style={{
            backgroundImage: "linear-gradient(orange, yellow)",
            marginTop: "40px",
            marginBottom: "40px",
          }}
        >
          <div
            style={{
              marginTop: "30px",
              marginBottom: "60px",
              textAlign: "center",
            }}
          >
            <div>
              {screenSize >= 930 ? (
                <h1 style={{ color: "red", fontWeight: "700" }}>
                  Anda punya Kebutuhan Produk / Jasa yang Sulit Dicari?
                </h1>
              ) : (
                <h4 style={{ color: "red", fontWeight: "700" }}>
                  Anda punya Kebutuhan Produk / Jasa yang Sulit Dicari?
                </h4>
              )}
            </div>
            <div style={{ marginTop: "40px" }}>
              {screenSize >= 930 ? (
                <h1 style={{ color: "red", fontWeight: "700" }}>
                  Anda punya Bisnis yang Kesulitan Mencari Pelanggan?
                </h1>
              ) : (
                <h4 style={{ color: "red", fontWeight: "700" }}>
                  Anda punya Bisnis yang Kesulitan Mencari Pelanggan?
                </h4>
              )}
            </div>
            <div style={{ marginTop: "40px" }}>
              {screenSize >= 930 ? (
                <h1 style={{ color: "green", fontWeight: "700" }}>
                  Tenang! Sekarang ada JARING UMKM!
                </h1>
              ) : (
                <h4 style={{ color: "green", fontWeight: "700" }}>
                  Tenang! Sekarang ada JARING UMKM!
                </h4>
              )}
            </div>
            <div style={{ marginTop: "40px" }}>
              <p style={{ fontWeight: "700" }}>
                Kami adalah komunitas yang mengumpulkan kebutuhan di market dan
                mengumpulkan UMKM yang menyediakan produk / jasa yang dapat
                menjadi solusinya.
              </p>
              <p style={{ fontWeight: "700" }}>
                Dari data Kebutuhan Konsumen & UMKM yang kami miliki, kami akan
                membuat koneksi untuk membantu kedua belah pihak menyelesaikan
                tujuan mereka.
              </p>
              <p style={{ fontWeight: "700" }}>
                Saat ini kami hanya beroperasi untuk Area Yogyakarta saja.
              </p>
            </div>
            <div style={{ marginTop: "40px" }}>
              <h3 style={{ color: "green", fontWeight: "700" }}>
                Jadi tunggu apalagi? Segera selesaikan permasalahanmu!
              </h3>
              <span style={{ fontSize: "100px" }}>&#8595;</span>
            </div>
          </div>
        </Card>
        <div
          className="text-center text-white"
          style={{ backgroundColor: "#eae41f" }}
        >
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={() => {
                navigate("/submitDemand");
              }}
              style={{ backgroundImage: "linear-gradient(red, orange)" }}
            >
              {screenSize >= 930 ? (
                <h4>
                  Apakah Anda butuh Produk / Jasa yang Sulit Anda Temukan?
                </h4>
              ) : (
                <h5>
                  Apakah Anda butuh Produk / Jasa yang Sulit Anda Temukan?
                </h5>
              )}
              <p>KLIK DISINI!</p>
            </Button>
          </div>
          <div style={{ marginTop: "60px" }}>
            <Button
              onClick={() => {
                navigate("/submitSupply");
              }}
              style={{ backgroundImage: "linear-gradient(red, orange)" }}
            >
              {screenSize >= 930 ? (
                <h4>
                  Apakah Produk / Jasa milik Anda ingin Kami Promosikan secara
                  Gratis?
                </h4>
              ) : (
                <h5>
                  Apakah Produk / Jasa milik Anda ingin Kami Promosikan secara
                  Gratis?
                </h5>
              )}
              <p>KLIK DISINI!</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TentangKami;
