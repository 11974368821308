import React, { useState } from "react";
import axios from "axios";
import { Card, Form, Button } from "react-bootstrap";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tempUrl, useStateContext } from "../contexts/ContextProvider";
import { MenuNavbar } from "../components/index";

const SubmitDemand = () => {
  const { screenSize } = useStateContext();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [open, setOpen] = useState(false);
  const [namaLengkapDemand, setNamaLengkapDemand] = useState("");
  const [jenisBadanDemand, setJenisBadanDemand] = useState("PERORANGAN");
  const [namaInstitusiDemand, setNamaInstitusiDemand] = useState("");
  const [lokasiDemand, setLokasiDemand] = useState("");
  const [kontakDemand, setKontakDemand] = useState("");
  const [profesiDemand, setProfesiDemand] = useState("");
  const [produkJasaYangDibutuhkanDemand, setProdukJasaYangDibutuhkanDemand] =
    useState("");
  const [
    kenapaProdukJasaSulitDitemukanDemand,
    setKenapaProdukJasaSulitDitemukanDemand,
  ] = useState("");
  const [apaYangTelahAndaLakukanDemand, setApaYangTelahAndaLakukanDemand] =
    useState("");
  const [kenapaSolusiTersebutGagalDemand, setKenapaSolusiTersebutGagalDemand] =
    useState("");
  const [
    apakahBersediaMasukKoneksiDemand,
    setApakahBersediaMasukKoneksiDemand,
  ] = useState("YA");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const saveSubmitDemand = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      try {
        await axios.post(`${tempUrl}/saveSubmitDemand`, {
          namaLengkapDemand,
          jenisBadanDemand,
          namaInstitusiDemand,
          lokasiDemand,
          kontakDemand,
          profesiDemand,
          produkJasaYangDibutuhkanDemand,
          kenapaProdukJasaSulitDitemukanDemand,
          apaYangTelahAndaLakukanDemand,
          kenapaSolusiTersebutGagalDemand,
          apakahBersediaMasukKoneksiDemand,
        });
        navigate("/submittedDemand");
      } catch (error) {
        alert(error.response.data.message);
      }
    } else {
      setOpen(!open);
    }
    setValidated(true);
  };

  return (
    <div style={{ backgroundColor: "#eae41f" }}>
      <MenuNavbar />
      <div
        style={{
          backgroundImage: `url("/SpiderWebTransparent.png")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            paddingTop: "40px",
            paddingBottom: "40px",
            paddingLeft: screenSize >= 930 ? "80px" : "10px",
            paddingRight: screenSize >= 930 ? "80px" : "10px",
          }}
        >
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="outline-primary"
              onClick={() => {
                navigate("/");
              }}
            >
              {"< Kembali"}
            </Button>
          </div>
          <p
            style={{
              color: "red",
              fontWeight: "700",
              textShadow: "2px 2px 2px white",
            }}
          >
            *Data Anda akan dijaga, tidak disebar, dan hanya akan kami gunakan
            untuk membantu menyelesaikan permasalahan Anda.*
          </p>
          <div style={{ textAlign: "center", marginTop: "40px" }}>
            <h1
              style={{
                color: "red",
                fontWeight: "700",
                textShadow: "2px 2px 2px white",
              }}
            >
              Beri Tau Kebutuhan Anda
            </h1>
            <h1
              style={{
                color: "red",
                fontWeight: "700",
                textShadow: "2px 2px 2px white",
              }}
            >
              Kami Beri Solusi!
            </h1>
          </div>
          <div style={{ marginTop: "40px" }}>
            <Card
              border="primary"
              style={{
                backgroundImage: "linear-gradient(orange, yellow)",
              }}
            >
              <Card.Body>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={saveSubmitDemand}
                >
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      1. Siapa nama lengkap Anda?
                    </Form.Label>
                    <Form.Control
                      required
                      value={namaLengkapDemand}
                      onChange={(e) =>
                        setNamaLengkapDemand(e.target.value.toUpperCase())
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Nama Lengkap harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      2. Apakah Anda perorangan atau dari institusi /
                      organisasi?
                    </Form.Label>
                    <Form.Select
                      onChange={(e) =>
                        setJenisBadanDemand(e.target.value.toUpperCase())
                      }
                    >
                      <option value="PERORANGAN">PERORANGAN</option>
                      <option value="INSTITUSI / ORGANISASI">
                        INSTITUSI / ORGANISASI
                      </option>
                    </Form.Select>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      3. Apa nama Institusi / Organisasi Anda? (Jika bukan
                      perorangan)
                    </Form.Label>
                    <Form.Control
                      value={namaInstitusiDemand}
                      onChange={(e) =>
                        setNamaInstitusiDemand(e.target.value.toUpperCase())
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      4. Dimana lokasi domisili Anda?
                    </Form.Label>
                    <Form.Control
                      required
                      value={lokasiDemand}
                      onChange={(e) =>
                        setLokasiDemand(e.target.value.toUpperCase())
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Lokasi Domisili harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      5. Kontak Anda yang dapat dihubungi (Nomor Whatsapp untuk
                      insentif, boleh IG, Facebook, dll)
                    </Form.Label>
                    <Form.Control
                      required
                      value={kontakDemand}
                      onChange={(e) =>
                        setKontakDemand(e.target.value.toUpperCase())
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Kontak Anda harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      6. Apa profesi Anda?
                    </Form.Label>
                    <Form.Control
                      value={profesiDemand}
                      onChange={(e) =>
                        setProfesiDemand(e.target.value.toUpperCase())
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      7. Apa produk / jasa yang Anda butuhkan namun belum
                      berhasil terpenuhi sekarang?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      required
                      value={produkJasaYangDibutuhkanDemand}
                      onChange={(e) =>
                        setProdukJasaYangDibutuhkanDemand(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Produk / Jasa harus diisi!
                    </Form.Control.Feedback>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      8. Kenapa produk / jasa tersebut sulit Anda temukan?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={kenapaProdukJasaSulitDitemukanDemand}
                      onChange={(e) =>
                        setKenapaProdukJasaSulitDitemukanDemand(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      9. Apa yang telah Anda lakukan untuk mencari produk / jasa
                      tersebut?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={apaYangTelahAndaLakukanDemand}
                      onChange={(e) =>
                        setApaYangTelahAndaLakukanDemand(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      10. Kenapa solusi Anda tersebut gagal memenuhi kebutuhan
                      produk / jasa Anda tadi?
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={kenapaSolusiTersebutGagalDemand}
                      onChange={(e) =>
                        setKenapaSolusiTersebutGagalDemand(
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Form.Label htmlFor="inputPassword5">
                      11. Kami akan merujuk Anda ke koneksi UMKM kami dengan
                      harapan, kami dapat membantu Anda menemukan produk / jasa
                      yang tepat. Apakah Anda berkenan?
                    </Form.Label>
                    <Form.Select
                      onChange={(e) =>
                        setApakahBersediaMasukKoneksiDemand(
                          e.target.value.toUpperCase()
                        )
                      }
                    >
                      <option value="YA">YA</option>
                      <option value="TIDAK">TIDAK</option>
                    </Form.Select>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Button variant="primary" type="submit">
                      Kirim
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={alertBox}>
          Data belum terisi semua!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SubmitDemand;

const alertBox = {
  width: "100%",
};
